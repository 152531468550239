import React, { useState } from "react";

export function ShopCard({ name, image, link, logo }) {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="ph3-l pv3 w-third-l"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`aspect-ratio aspect-ratio--1x1 bg-animate ${
          logo && hover ? "bg-white" : "bg-gray"
        }`}
      >
        <div className="aspect-ratio--object z-3 flex justify-center items-center">
          <div
            className={`text-shadow white f2 fw5 lh-title tc ph2 animate__animated ${
              hover ? "animate__fadeOut" : "animate__fadeIn"
            }`}
          >
            {name}
          </div>
        </div>

        {image && (
          <img
            src={image}
            alt={name}
            className={`object-cover absolute z-1 top-0 left-0 w-100 h-100 o-animate ${
              hover ? (logo ? "o-0" : "o-100") : "o-70"
            }`}
          />
        )}

        {logo && (
          <img
            src={logo}
            alt={name}
            className={`object-contain absolute z-2 top-0 left-0 w-100 h-100 o-animate b--transparent ba bw4 border-box ${
              hover ? "o-100" : image ? "o-0" : "o-70"
            }`}
          />
        )}

        {link && (
          <a
            href={link}
            className="absolute db absolute--fill z-3 pointer"
            target="_blank"
            rel="noopen nofollow noreferrer"
          >
            <span className="o-0">{name}</span>
          </a>
        )}
      </div>
    </div>
  );
}

export default ShopCard;
