import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Anchor, PageWidth } from "../components";
import { useSettings } from "../hooks";
import HillPartner from "./hill-partner-logo.png";

export function Footer() {
  const { title } = useSettings();
  const { data } = useQuery(FooterMenu);
  const menu = data?.menuItems?.nodes || [];

  return (
    <footer id="footer">
      <div className="bg-secondary white pv3">
        <PageWidth className="overflow-hidden tc tl-l">
          <div className="flex-l items-center-l nt3-l nb3-l nl3-l nr3-l">
            <div className="w-third-l ph3-l pv3">
              <nav className="lh-copy">
                {menu.map((node) => (
                  <MenuLink key={node.id} {...node} />
                ))}
              </nav>
            </div>

            <div className="w-third-l ph3-l pv3 tc f6">
              <div>4777 Sharon Rd, Charlotte, NC 28210</div>
            </div>

            <div className="w-third-l ph3-l pv3">
              <Anchor href="https://www.hillpartnersinc.com/">
                <img
                  src={HillPartner}
                  className="w4 db-l ml-auto-l"
                  alt="Hill Partners"
                />
              </Anchor>
            </div>
          </div>

          <div className="f7 tc">
            Copyright &copy; {new Date().getFullYear()} {title}
          </div>
        </PageWidth>
      </div>
    </footer>
  );
}

const FooterMenu = gql`
  query FooterMenu {
    menuItems(where: { location: FOOTER_MENU }) {
      nodes {
        id
        url
        label
        connectedNode {
          node {
            __typename
          }
        }
      }
    }
  }
`;

function MenuLink({ url, label }) {
  const props = { className: "color-inherit no-underline db mh2" };

  props.href = url;

  return <Anchor {...props}>{label}</Anchor>;
}

export default Footer;
