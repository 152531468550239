import React from "react";
import { PageWidth, Title, Anchor } from "../components";
import PostContent from "./PostContent";

export function Single({ node }) {
  const { title, content, name, categories, date } = node;

  return (
    <>
      <Title>{title || name}</Title>
      <PageWidth>
        {date && <Meta uri={node.uri} {...{ categories, date }} />}
        <PostContent>{content}</PostContent>
      </PageWidth>
    </>
  );
}

function Meta({ categories, date, uri }) {
  return (
    <div className="post-meta mv4">
      <div className="inline-flex items-center mt2 f6 mt0-ns">
        <div className="mr2">🕥</div>
        <div>{date}</div>
      </div>

      {categories?.edges?.length > 0 && (
        <div className="post-categories inline-flex items-center ml3">
          <div className="mr2">📁</div>
          <ul className="list pl0 flex">
            {categories.edges.map((category, index) => {
              let className = "";
              if (index > 0) {
                className = "ml2 pl2 bl b--near-white";
              }

              return (
                <li key={category.node.id} {...{ className }}>
                  <Anchor href={uri} className="no-underline primary">
                    {category.node.name}
                  </Anchor>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Single;
