import { gql } from "@apollo/client";
import CreatePaginationQuery from "./CreatePaginationQuery";

export const LiteralSeo = `
  title
  metaDesc
  breadcrumbs {
    url
    text
  }
`;

export const FragmentPost = gql`
  fragment PostFragment on Post {
    id
    databaseId
    uri
    title
    excerpt
    content
    date
    isRestricted
    isPreview
    seo {
      ${LiteralSeo}
    }
    categories(first: 5) {
      edges {
        node {
          id
          databaseId
          slug
          name
          uri
        }
      }
    }
  }
`;

export const FragmentPage = gql`
  fragment PageFragment on Page {
    id
    databaseId
    uri
    title
    content
    featuredImage {
      node {
        src: mediaItemUrl
        altText
      }
    }
    seo {
      ${LiteralSeo}
    }
  }
`;

export const FragmentContentType = gql`
  fragment ContentTypeFragment on ContentType {
    id
    title: graphqlPluralName
    ${CreatePaginationQuery("contentNodes", "...PostFragment")}
  }
`;

export const FragmentUserArchive = gql`
  fragment UserArchiveFragment on User {
    id
    name
    ${CreatePaginationQuery("posts", "...PostFragment")}
  }
`;

export const FragmentCategory = gql`
  fragment CategoryFragment on Category {
    id
    databaseId
    slug
    name
    uri
    seo {
      ${LiteralSeo}
    }
    ${CreatePaginationQuery("posts", "...PostFragment")}
  }
`;

export const FragmentTag = gql`
  fragment TagFragment on Tag {
    id
    databaseId
    slug
    name
    uri
    seo {
      ${LiteralSeo}
    }
    ${CreatePaginationQuery("posts", "...PostFragment")}
  }
`;

export const AcfFragment = gql`
  fragment AcfFragment on AdvancedCustomFields {
    heroTitle
    heroSubtitle
    heroContent
    heroButton
    heroButtonUrl
  }
`;