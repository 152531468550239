import React, { forwardRef } from "react";

export const PageWidth = forwardRef(function (props, ref) {
  const { children, className } = props;

  return (
    <div className={`w-100 mw9 ph3 ph4-l center ${className || ""}`} {...{ ref }}>
      {children}
    </div>
  );
});

export default PageWidth;
