import React from "react";
import Menu from "./Menu";
import Logo from "./Logo";
import { useLocation } from "react-router-dom";

export function Header({ className }) {
  const { pathname } = useLocation();

  return (
    <header id="header" {...{ className }}>
      <div className="fixed-l z-5 w-100 bg-white">
        <Menu>
          <Logo h1={pathname === "/"} />
        </Menu>
      </div>
    </header>
  );
}

export default Header;
