import React from "react";
import { Map, PageWidth } from "../components";
import DefaultForm from "../form-default";

export default function Contact() {
  return (
    <div className="pv4 gradient-1" id="contact">
      <div className="tc lh-title f2 fw5 mb4">Contact Us</div>
      <PageWidth className="mb4">
        Thank you for your interest in Sharon Square. Please fill out the form
        below and someone will get back to you as quickly as possible.
      </PageWidth>

      <PageWidth>
        <div className="bg-white br4 overflow-hidden">
          <div className="flex-l items-stretch-l">
            <div className="w-60-l pa4">
              <DefaultForm />
            </div>
            <div className="w-40-l relative z-1">
              <Map />
              <div className="relative z-2 o-animate flex justify-end">
                <div
                  className="bg-secondary white pa3 w-50-l"
                  style={{ borderRadius: "0 0 0 1rem" }}
                >
                  Sharon Square is located at {process.env.REACT_APP_ADDRESS}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWidth>
    </div>
  );
}
