import React, { useRef } from "react";
import parse from "html-react-parser";
import RestaurantsAndShops from "../ras";
import Bold from "../bold";
import { PageWidth, Anchor } from "../components";

function trimString(str) {
  if (!str) return str;

  let _str = str.replace(/(<([^>]+)>)/gi, "");
  if (_str.length > 250) {
    _str = _str.substring(0, 250) + "&hellip;";
  }
  return _str;
}

export function PostContent(props) {
  const { className, content, children, trim = false } = props;
  const keyIncrement = useRef(0);

  let text = content || children;
  if (trim && typeof text === "string") {
    text = trimString(text);
  }

  const RecursiveProps = (_node) => {
    const node =
      typeof _node === "string"
        ? _node
        : { ..._node, props: { ..._node?.props } };

    if (node?.props) {
      node.key = `post-content-${keyIncrement.current}`;
      keyIncrement.current++;
    } else if (typeof node === "string") {
      return node;
    }

    const dataProps = {};
    Object.keys(node?.props || []).forEach((_key) => {
      if (_key.indexOf("data-") === 0) {
        dataProps[_key.replace("data-", "")] = node.props[_key];
      }
    });

    if (node.type === "a") {
      if (!node.props.className) {
        node.props.className = "primary no-underline";
      }

      return <Anchor key={node.key} {...node.props} />;
    }

    if (node?.props?.className?.includes("wp-block-columns")) {
      return (
        <div className="gradient-1 pv4 relative z-1" key={node.key}>
          <div id="about" className="nt5 absolute top-0 left-0" />

          <PageWidth>
            <div className={node.props.className}>
              <>{node.props.children.map((child) => RecursiveProps(child))}</>
            </div>
          </PageWidth>
        </div>
      );
    }

    if (node?.props?.className === "wp-block-sharon-square-ras") {
      return <RestaurantsAndShops key={node.key} {...dataProps} />;
    }

    if (node?.props?.className === "wp-block-sharon-square-bold") {
      return <Bold {...dataProps} key={node.key} />;
    }

    // Return a loop for children arrays.
    if (
      Array.isArray(node?.props?.children) &&
      node?.props?.children?.length > 0
    ) {
      return {
        ...node,
        props: {
          ...node.props,
          children: node.props.children.map((child) => RecursiveProps(child)),
        },
      };
    }

    // Return the node for single children.
    if (node?.props?.children?.["$$typeof"]) {
      return {
        ...node,
        props: {
          ...node.props,
          children: RecursiveProps(node.props.children),
        },
      };
    }

    return node;
  };

  if (text && typeof text === "string") {
    const reactConversion = parse(text);

    if (reactConversion?.length > 0 && reactConversion.map) {
      return (
        <div className={`post-content ${className || ""}`}>
          {reactConversion.map((node) => RecursiveProps(node))}
        </div>
      );
    }
  }

  return (
    <div
      className={`post-content lh-large ${className || ""}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

export default PostContent;
