import React from "react";
import { Button } from "../components";

export default function Bold({ image, title, content, link }) {
  return (
    <div
      className="vh-100-l flex items-center pv4 relative z-1 mb4"
      id={title.toLowerCase().replace(" ", "-")}
    >
      <div className="relative z-2 pa4 bg-white mw7">
        <div className="lh-title f2 fw5 mb4">{title}</div>
        <div className="mb4" dangerouslySetInnerHTML={{ __html: content }} />

        <div className="mt3">
          <Button href={link}>Learn More</Button>
        </div>
      </div>

      <img
        className="object-cover absolute w-100 h-100 z-1"
        src={image}
        alt={title}
      />
    </div>
  );
}
