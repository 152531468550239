import React from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

export function Map() {
  return (
    <div
      className="absolute-l absolute--fill-l h5 h-auto-l db"
      onClick={() =>
        (window.location.href =
          "https://www.google.com/maps/place/Sharon+Square/@35.1466338,-80.833307,17z/data=!4m12!1m6!3m5!1s0x88569e135c70e593:0x3e28b0fd74ddbe34!2sSharon+Square!8m2!3d35.1463678!4d-80.8307071!3m4!1s0x88569e135c70e593:0x3e28b0fd74ddbe34!8m2!3d35.1463678!4d-80.8307071")
      }
    >
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GMAP}
        preventGoogleFonts
      >
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={{ lat: 35.1466338, lng: -80.833307 }}
          options={GoogleMap_options}
          zoom={15}
        >
          <Marker position={{ lat: 35.1466338, lng: -80.833307 }} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

const GoogleMap_options = { gestureHandling: "none", disableDefaultUI: true };

export default Map;
