import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./auth";
import { NodeProvider, Preloader } from "./node";
import { SearchProvider } from "./search";

const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export function AppProvider({ children }) {
  const search = useRef({});
  const preloader = useRef({});

  return (
    <HelmetProvider>
      <AppContext.Provider
        value={{
          search,
          preloader,
        }}
      >
        <NodeProvider>
          <SearchProvider>
            <AuthProvider>
              <UiProvider>
                {children}
                <Preloader />
              </UiProvider>
            </AuthProvider>
          </SearchProvider>
        </NodeProvider>
      </AppContext.Provider>
    </HelmetProvider>
  );
}

const mobile = window.matchMedia("screen and max-width: 30rem)").matches;

function UiProvider({ children }) {
  const mobileCheckRef = useRef();
  const [isDesktop, setIsDesktop] = useState(!mobile);

  useEffect(() => {
    let timeout;

    const UpdateView = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        if (mobileCheckRef.current) {
          setIsDesktop(mobileCheckRef.current.offsetParent === null);
        }
      }, 25);
    };

    UpdateView();
    window.addEventListener("resize", UpdateView);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", UpdateView);
    };
  }, []);

  return (
    <UiContext.Provider
      value={{
        isMobile: !isDesktop,
        isDesktop,
      }}
    >
      {children}
      <div ref={mobileCheckRef} className="db dn-l" />
    </UiContext.Provider>
  );
}

const UiContext = createContext({});
export const useUiContext = () => useContext(UiContext);

export default AppProvider;
