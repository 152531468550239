import { gql, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useNodeUpdaterContext } from "./Context";
import {
  FragmentContentType,
  FragmentCategory,
  FragmentTag,
  FragmentPage,
  FragmentPost,
  FragmentUserArchive,
  AcfFragment,
} from "./Fragments";

export function useNode(props) {
  const {
    mainQuery,
    query: queryProp,
    databaseId: idProp,
    uri,
    perPage,
    variables: varProp = {},
    skip,
    fetchPolicy,
  } = props || {};
  const { updater } = useNodeUpdaterContext();
  const { pathname, search } = useLocation();
  const previewId = new URLSearchParams(search).get("p");
  const databaseId = previewId || idProp;
  const query = useMemo(
    () => queryProp || (databaseId ? IdQuery : UriQuery),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const variables = useMemo(() => {
    const _variables = { first: perPage || 12 };

    if (databaseId) {
      _variables.databaseId = databaseId;
    }

    if (uri) {
      _variables.uri = uri;
    } else {
      _variables.uri = pathname;
    }

    return _variables;
  }, [pathname, uri]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildEdges = useCallback(
    (_data) => {
      let _edges = _data?.node?.posts
        ? _data.node.posts.edges || []
        : _data?.posts?.edges || [];

      if (~~variables.last > 0) {
        _edges = [..._edges].reverse();
      }

      return _edges;
    },
    [variables]
  );

  const results = useQuery(query, {
    variables: { ...variables, ...varProp },
    fetchPolicy,
    skip,
  });

  const { data, loading, error, fetchMore } = results;

  const called = useMemo(() => data || !loading, [data, loading]);
  const node = useMemo(() => data?.node || {}, [data]);
  const edges = useMemo(() => buildEdges(data), [data, buildEdges]);
  const acf = useMemo(() => data?.advancedCustomFields || {}, [data]);

  /**
   * Updates the mainQuery context state.
   */
  useEffect(() => {
    const { updateNode = () => {} } = updater.current;

    if (mainQuery) {
      updateNode({
        ...results,
        node: results?.data?.node || {},
        edges: buildEdges(results?.data),
        acf: results?.data?.advancedCustomFields || {}
      });
    }

    return () => {
      if (mainQuery) {
        updateNode({ action: "RESET" });
      }
    };
  }, [mainQuery, results, updater, buildEdges]); // eslint-disable-line react-hooks/exhaustive-deps

  const meta = { __typename: node.__typename, pageInfo: {} };

  if (node.posts) {
    meta.pageInfo = node.posts?.pageInfo || {};
    meta.__typename = node?.posts?.edges?.[0]?.node?.__typename || null;
    meta.title = node?.name;
    meta.seo = node?.seo;
    meta.isArchiveNode = true;
  } else if (data?.posts) {
    meta.pageInfo = data.posts.pageInfo || {};
    meta.__typename = data?.posts?.edges?.[0]?.node?.__typename || null;
    meta.isArchiveNode = true;
  }

  return {
    acf,
    node,
    edges,
    loading,
    error,
    fetchMore,
    called,
    ...meta,
  };
}

const UriQuery = gql`
  query Node(
    $uri: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    node: nodeByUri(uri: $uri) {
      __typename
      ...PostFragment
      ...PageFragment
      ...CategoryFragment
      ...TagFragment
      ...ContentTypeFragment
      ...UserArchiveFragment
    }
    advancedCustomFields(uri: $uri) {
      ...AcfFragment
    }
  }
  ${FragmentContentType}
  ${FragmentCategory}
  ${FragmentTag}
  ${FragmentPage}
  ${FragmentPost}
  ${FragmentUserArchive}
  ${AcfFragment}
`;

const IdQuery = gql`
  query ContentNodeId($databaseId: ID!) {
    node: contentNode(id: $databaseId, idType: DATABASE_ID) {
      __typename
      ...PostFragment
      ...PageFragment
    }
    advancedCustomFields(uri: $uri) {
      ...AcfFragment
    }
  }
  ${FragmentPage}
  ${FragmentPost}
  ${AcfFragment}
`;

export default useNode;
