import React, { forwardRef } from "react";

const InputClasses =
  "db w-100 f5 ba pa2 border-box bg-transparent b--moon-gray";

export const Input = forwardRef(function (
  { type = "text", className: classNameProp, options, ...props },
  ref
) {
  const className = `${InputClasses} ${classNameProp || ""}`;

  const ComponentProps = { ref, className, ...props };

  let sanitizedOptions;

  if (options) {
    sanitizedOptions = options.map((item) => ({
      value: item?.value || item,
      label: item?.label || item?.value || item,
    }));
  }

  switch (type) {
    case "select":
      return (
        <select {...ComponentProps}>
          {options
            ? sanitizedOptions.map(({ value, label }) => (
                <option key={value} {...{ value }}>
                  {label}
                </option>
              ))
            : ComponentProps.children}
        </select>
      );
    case "textarea":
      if (!ComponentProps.rows) {
        ComponentProps.rows = 5;
      }
      return <textarea {...ComponentProps} />;
    case "radio":
    case "checkbox":
      ComponentProps.type = type;
      const {
        className,
        value: fieldValue,
        id,
        inline,
        ...CheckboxProps
      } = ComponentProps;
      return (
        <div className={inline ? "flex justify-around flex-auto f6" : ""}>
          {sanitizedOptions.map(({ value, label }, index) => {
            return (
              <label
                key={`${id}${value}`}
                htmlFor={`${id}${index}`}
                className={`db pointer ${!inline ? "mv3" : ""}`}
              >
                <input
                  name={id}
                  key={value}
                  id={`${id}${index}`}
                  checked={
                    Array.isArray(fieldValue)
                      ? fieldValue.includes(value)
                      : fieldValue === value
                  }
                  {...{ value }}
                  {...CheckboxProps}
                  className="mr2 dib"
                />
                {label}
              </label>
            );
          })}
        </div>
      );
    default:
      ComponentProps.type = type;
      return <input {...ComponentProps} />;
  }
});

export default Input;
