import React from "react";
import formSchema from "./schema";
import { Button, Input } from "../components";
import { Field } from "../form";
import { useDefaultFormContext } from "./Context";

export default function Form({ className }) {
  const { onClick } = useDefaultFormContext();
  return (
    <div {...{ className }}>
      <div className="overflow-hidden">
        <div className="flex nl2 nr2 nb2 nt2">
          <DefaultField id="firstName" className="pa2 w-50-l" />
          <DefaultField id="lastName" className="pa2 w-50-l" />
        </div>

        <div className="flex nl2 nr2 nb2 nt2">
          <DefaultField id="email" className="pa2 w-50-l" />
          <DefaultField id="phone" className="pa2 w-50-l" />
        </div>

        <div className="flex nl2 nr2 nb2 nt2">
          <DefaultField id="message" className="pa2 w-50-l" />
          <DefaultField id="inquiry" className="pa2 w-50-l" />
        </div>
      </div>

      <Button {...{ onClick }} form>
        Send Message
      </Button>
    </div>
  );
}

function DefaultField({ id, className }) {
  const { type, options, valid, label, errorMessage, required, ...field } =
    formSchema[id];
  const { form, updateForm, errors, token } = useDefaultFormContext();
  const value = form[id] || "";
  const onChange = (evt) => {
    updateForm({ id, value: evt.target.value });
  };

  return (
    <div {...{ className }}>
      <Field
        {...{ id, label }}
        required={required !== undefined ? required : !!errorMessage}
      >
        <Input {...{ value, onChange, type, options }} />
      </Field>
      {errors[id] && <div className="red f6 fw7 pa2">{errors[id]}</div>}
      {field.onValid && (!valid || valid(value)) && (
        <field.onValid {...{ token }} />
      )}
    </div>
  );
}
