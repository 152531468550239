import React from "react";
import { useLocation } from "react-router";

export function Main({ children }) {
  const { pathname } = useLocation();
  return (
    <div className="main lh-copy relative z-1 flex-auto">
      {pathname !== "/" && <div className="pt6 dn db-l" />}
      {children}
      {pathname !== "/" && <div className="pt6 dn db-l" />}
    </div>
  );
}

export default Main;
