import { gql, useQuery } from "@apollo/client";

export function useShops() {
  const { data, loading, error } = useQuery(Query);

  const shops = data?.shops?.nodes || [];

  return { shops, loading, error };
}

const Query = gql`
  query Shops {
    shops(first: 99, where: { orderby: { field: TITLE, order: ASC } }) {
      nodes {
        id
        title
        link: externalUrl
        primaryImage {
          node {
            src: sourceUrl(size: MEDIUM)
            altText
          }
        }
        logo {
          node {
            src: sourceUrl(size: MEDIUM)
            altText
          }
        }
      }
    }
  }
`;

export default useShops;
