import React from "react";
import { PostContent, useNode, Seo } from "../node";
import { Button } from "../components";
import Contact from "./Contact";

export function Home() {
  const {
    node: { seo, content, featuredImage },
    acf: { heroTitle, heroSubtitle, heroContent, heroButton, heroButtonUrl },
  } = useNode({ mainQuery: true });

  return (
    <>
      <Seo {...seo} />

      <div className="vh-100-l flex items-center pv4 relative z-1">
        <div className="relative z-2 pa4 bg-white">
          {heroTitle && <div className="lh-solid fw4 f1 mb3">{heroTitle}</div>}
          {heroSubtitle && (
            <div className="lh-solid fw4 f2 accent ttu">{heroSubtitle}</div>
          )}
          {heroContent && <div className="lh-solid mt4">{heroContent}</div>}
          {heroButton && (
            <div>
              <Button href={heroButtonUrl}>{heroButton}</Button>
            </div>
          )}
        </div>

        {featuredImage && (
          <img
            className="object-cover absolute w-100 h-100 z-1"
            src={featuredImage.node.src}
            alt={featuredImage.node.altText}
          />
        )}
      </div>

      <PostContent>{content}</PostContent>

      <Contact />
    </>
  );
}

export default Home;
