import React from "react";
import ShopCard from "./ShopCard";
import useShops from "./useShops";
import { PageWidth } from "../components";

export default function RestaurantsAndShops({ title, content }) {
  const { shops } = useShops();

  return (
    <div className="pv4 relative z-1">
      <div
        className="absolute top-0 left-0 nt6"
        id="restaurants"
        style={{ pointerEvents: "none" }}
      />

      <div className="tc lh-title f2 fw5 mb4">{title}</div>
      <PageWidth>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </PageWidth>

      <PageWidth className="overflow-hidden">
        <div className="flex-l nl3-l nr3-l flex-wrap-l justify-center-l">
          {shops.map((shop) => (
            <ShopCard
              key={shop.id}
              link={shop.link}
              name={shop.title}
              image={shop.primaryImage?.node?.src}
              logo={shop.logo?.node?.src}
            />
          ))}
        </div>
      </PageWidth>
    </div>
  );
}
