import React, { useRef } from "react";
import Submenu from "./Submenu";
import { Anchor } from "../components";
import { ReactComponent as Carat } from "./carat.svg";
import { useLocation } from "react-router-dom";
import { useUiContext } from "../Context";

export function MenuItem({ setHover, hover, className, item, subMenu = {}, closeMobile }) {
  const { pathname } = useLocation();
  const { isDesktop } = useUiContext();
  const hasHovered = useRef(false);

  const DivProps = { className: "relative", key: item.id };

  const AnchorProps = {
    className,
    onClick: closeMobile,
  };

  if (isDesktop) {
    AnchorProps.onMouseEnter = () => {
      hasHovered.current = true;
      setHover(item.id);
    };
  }

  const SpanProps = { className: "db" };

  if (
    process.env.REACT_APP_DOMAIN + pathname === item.url ||
    hover === item.id
  ) {
    AnchorProps.className += " secondary";
  } else {
    AnchorProps.className += " color-inherit";
  }

  if (item.cssClasses?.length > 0) {
    SpanProps.className += " " + item.cssClasses.join(" ");
  }

  if (hover === item.id) {
    DivProps.className += " z-2";
  } else {
    DivProps.className += " z-1";
  }

  const hasChildren = item.childItems?.nodes?.length > 0;

  return (
    <div {...DivProps}>
      <div className="flex items-center nb2-l">
        <Anchor href={item.url} {...AnchorProps}>
          <span {...SpanProps}>{item.label}</span>
          {isDesktop && (
            <span
              className={`db b--secondary ${
                hover === item.id
                  ? "animate__fadeIn"
                  : hasHovered.current
                  ? "animate__fadeOut"
                  : "o-0"
              } animate__animated animate__faster bb bw2 mt3 nl3 nr3`}
            />
          )}
        </Anchor>
        {!isDesktop && hasChildren ? (
          <span
            className="ml-auto db pa1 pointer"
            onClick={() => setHover(item.id)}
          >
            <Carat />
          </span>
        ) : null}
      </div>

      {hasChildren && hover === item.id ? (
        <Submenu items={item.childItems.nodes} {...subMenu} />
      ) : null}
    </div>
  );
}

export default MenuItem;
