import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { monthNames } from "./MonthNames";

const relay = relayStylePagination();

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        contentNodes: {
          ...relay,
          keyArgs: function (args) {
            if (args.where.search) {
              return ["where"];
            }

            return false;
          },
        },
        posts: relay,
      },
    },
    User: {
      fields: {
        posts: relay,
      },
    },
    Category: {
      fields: {
        posts: relay,
      },
    },
    Tag: {
      fields: {
        posts: relay,
      },
    },
    ContentType: {
      fields: {
        contentNodes: relay,
        graphqlPluralName: {
          read: function (data) {
            if (data) {
              return data.charAt(0).toUpperCase() + data.slice(1);
            }

            return data;
          },
        },
      },
    },
    Post: {
      fields: {
        date: {
          read: formatDate,
        },
      },
    },
  },
});

function formatDate(date) {
  const d = new Date(date);

  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}
